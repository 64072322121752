// src/pages/AboutPage.js
import React from 'react';

function AboutPage() {
  return (
    <div>
      <h1>About Us</h1>
      <p>Welcome to PuckScouts! Here you can find information about our mission and team.</p>
    </div>
  );
}

export default AboutPage;
